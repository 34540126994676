/* eslint-disable react/jsx-props-no-spreading */
const CloseIcon = ({ bgColor = "#000", iconColor = "#fff", ...props }) => (
	<svg width={32} height={32} viewBox="0 0 32 32" {...props}>
		<g fill="none" fillRule="evenodd">
			<circle cx="16" cy="16" r="16" fill={bgColor} opacity=".7" />
			<g
				stroke={iconColor}
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
			>
				<path d="M0 8L8 0" transform="translate(12 12)" />
				<path d="M0 8L8 0" transform="translate(12 12) matrix(-1 0 0 1 8 0)" />
			</g>
		</g>
	</svg>
);

export default CloseIcon;
