import React from "react";

const ChevronDownIcon = ({ fill = "currentColor", ...props }) => (
	<svg
		width={10}
		height={5}
		viewBox="0 0 8 4"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M0.206748 0.175736C0.482412 -0.0585786 0.929352 -0.0585786 1.20502 0.175736L4 2.55147L6.79498 0.175736C7.07065 -0.0585786 7.51759 -0.0585786 7.79325 0.175736C8.06892 0.410051 8.06892 0.789949 7.79325 1.02426L4.49913 3.82426C4.22347 4.05858 3.77653 4.05858 3.50087 3.82426L0.206748 1.02426C-0.0689161 0.789949 -0.0689161 0.410051 0.206748 0.175736Z"
			// fill="#697386"
			fill={fill}
		/>
	</svg>
);

export default ChevronDownIcon;
