import { useEffect } from "react";

const useGTMOnInteraction = (gtmId) => {
	// Function to load GTM script on user interaction
	const loadGTMOnInteraction = () => {
		window.dataLayer = window.dataLayer || [];
		function gtag() {
			dataLayer.push(arguments);
		}
		gtag("js", new Date());
		gtag("config", gtmId);

		// Load GTM script
		const script = document.createElement("script");
		script.async = true;
		script.src = `https://www.googletagmanager.com/gtag/js?id=${gtmId}`;
		script.onload = () => {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: "gtm.js",
				"gtm.start": new Date().getTime(),
			});
		};

		document.body.appendChild(script);
	};

	useEffect(() => {
		// Add event listener for user interaction
		const handleClick = () => {
			loadGTMOnInteraction();

			// Remove event listeners after GTM loaded once
			document.removeEventListener("click", handleClick);
			document.removeEventListener("keydown", handleClick);
			document.removeEventListener("mousemove", handleClick);
			document.removeEventListener("touchstart", handleClick);
		};

		document.addEventListener("click", handleClick);
		document.addEventListener("keydown", handleClick);
		document.addEventListener("mousemove", handleClick);
		document.addEventListener("touchstart", handleClick);

		// Cleanup function to remove event listeners
		return () => {
			document.removeEventListener("click", handleClick);
			document.removeEventListener("keydown", handleClick);
			document.removeEventListener("mousemove", handleClick);
			document.removeEventListener("touchstart", handleClick);
		};
	}, [gtmId]);
};

export default useGTMOnInteraction;
