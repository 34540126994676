/* eslint-disable no-unused-expressions */
import { useState } from "react";
import Image from "next/image";
import config from "../../config";

const gumletLoader = ({ src, width, quality }) =>
	`${config.gumletBaseUrl}${src}?w=${width}&q=${quality}`;

/* eslint-disable react/jsx-props-no-spreading */
const CustomImage = ({
	src,
	alt = "",
	backupSrc = "/cloudinary/person.svg",
	showLoading = undefined,
	loader = null,
	height = undefined,
	width = undefined,
	quality = 75,
	showbackup = true,
	priority = false,
	...props
}) => {
	const [isLoading, toggleLoading] = useState(true);
	const [imageSource, setImageSource] = useState(
		src && src.length > 0 ? src : backupSrc
	);

	const [isError, setError] = useState(false);

	const handleError = () => {
		showbackup && setImageSource(backupSrc);
		toggleLoading(false);
		setError(true);
	};

	const handleOnLoad = () => {
		toggleLoading(false);
	};
	if (isLoading && showLoading) {
		return loader;
	}

	if (!showbackup && isError) {
		return null;
	}

	if (priority) {
		return (
			<Image
				loader={gumletLoader}
				src={imageSource}
				onError={handleError}
				onLoad={handleOnLoad}
				alt={alt}
				quality={quality}
				height={height}
				width={width}
				data-gmlazy="false"
				priority={priority}
				layout={width > 0 ? "intrinsic" : "fill"}
				{...props}
			/>
		);
	}

	return (
		<Image
			loader={gumletLoader}
			src={imageSource}
			onError={handleError}
			onLoad={handleOnLoad}
			alt={alt}
			quality={quality}
			height={height}
			width={width}
			loading="lazy"
			layout={width > 0 ? "intrinsic" : "fill"}
			{...props}
		/>
	);
};

export default CustomImage;
