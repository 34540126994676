/* eslint-disable import/prefer-default-export */
import QueriesKeys from "constants/queryKeys";
import { useQuery } from "react-query";
import { isUserLoggedIn } from "utils";
import { getCandidateOnboardingComplete } from "utils/apiClientPrivate";

export const useCandidateProgress = () =>
	useQuery<unknown>(
		QueriesKeys.GET_CANDIDATE_ONBOARDING_COMPLETE,
		async () => {
			const { data } = await getCandidateOnboardingComplete();
			return {
				isCandidateOnboardingCompleted: data?.is_profile_complete,
			};
		},
		{
			enabled: !!isUserLoggedIn(),
			retry: false,
		}
	);
